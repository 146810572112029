/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { withPrefix } from "gatsby"
import { Helmet } from "react-helmet"
import { AppProvider } from "./src/providers/AppProvider"
import { AffiliateProvider } from "./src/providers/AffiliateProvider"
import DefaultThemeProvider from "./src/themes/default"

export const wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      <AffiliateProvider>
        <Helmet>
          <link
            href={withPrefix("assets/css/layout.css")}
            rel="stylesheet"
            type="text/css"
          />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com/"
            crossorigin
          />
          <link
            rel="preconnect"
            href="https://fonts.googleapis.com/"
            crossorigin
          />
        </Helmet>
        <DefaultThemeProvider>
          <div id="app">{element}</div>
        </DefaultThemeProvider>
      </AffiliateProvider>
    </AppProvider>
  )
}

export const onRouteUpdate = () => {
  //   if (typeof window !== `undefined`) {
  //     window.scrollTo(0, 0)
  //   }
}

export const shouldUpdateScroll = args => {
  return false
}
